#loaderBg {
  background-color: #fff;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
}

#preloader {
  background: conic-gradient(#2828280a, #2828280d, #000);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  animation: 1s linear infinite spin;
  display: flex;
}

#preloader:before {
  content: "";
  background-color: #fff;
  border-radius: 50%;
  width: 90%;
  height: 90%;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

#spinning {
  position: relative;
}

#logo {
  height: 35px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/*# sourceMappingURL=index.05a9f74d.css.map */
